<template>
  <svg viewBox="0 0 20 21">
    <path
      d="M3.33301 18.833C3.33301 17.0649 4.03539 15.3692 5.28563 14.119C6.53587 12.8687 8.23156 12.1663 9.99967 12.1663C11.7678 12.1663 13.4635 12.8687 14.7137 14.119C15.964 15.3692 16.6663 17.0649 16.6663 18.833H14.9997C14.9997 17.5069 14.4729 16.2352 13.5352 15.2975C12.5975 14.3598 11.3258 13.833 9.99967 13.833C8.67359 13.833 7.40182 14.3598 6.46414 15.2975C5.52646 16.2352 4.99967 17.5069 4.99967 18.833H3.33301ZM9.99967 11.333C7.23717 11.333 4.99967 9.09551 4.99967 6.33301C4.99967 3.57051 7.23717 1.33301 9.99967 1.33301C12.7622 1.33301 14.9997 3.57051 14.9997 6.33301C14.9997 9.09551 12.7622 11.333 9.99967 11.333ZM9.99967 9.66634C11.8413 9.66634 13.333 8.17467 13.333 6.33301C13.333 4.49134 11.8413 2.99967 9.99967 2.99967C8.15801 2.99967 6.66634 4.49134 6.66634 6.33301C6.66634 8.17467 8.15801 9.66634 9.99967 9.66634Z"
    />
  </svg>
</template>

<script>
export default {
  name: "EditProfileIcon",
};
</script>
